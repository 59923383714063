
import { computed, defineComponent, onMounted, ref, reactive, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import contacts from "@/core/data/contacts";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import store from "@/store";
import mAxiosApi from "@/api";
import * as moment from "moment";


export default defineComponent({
  name: "private-chat",
  components: {
    MessageIn,
    MessageOut,
  },

  props: {
    dis_type_entity : {
      default: "stillnetwork",
    },
    dis_knum_entity : {
      default: 1,
    },
    deltaHauteur : {
      default: 0,
    }
  },

  setup(props) {

    moment.default.locale("fr");
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const handleCommand = (command: string | number ) => {
      newMessageText.value += command;
    }

    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      messages : [] as any,
      files: [] as any,
      loaderUpload : false,
      dernierID : 0,
      title : "",
    });


    const newMessageText = ref("");


    const addNewMessage = () => {
      if (!newMessageText.value && state.files.length == 0 ) {
        return;
      }

      const form = {
        dis_c_message : newMessageText.value,
        dis_knum_entity : props.dis_knum_entity,
        dis_type_entity : props.dis_type_entity,
        files : state.files,
      }
      
      mAxiosApi
        .post("/addMessage", form)
        .then( async() => {
            newMessageText.value = "";
            state.files = [];
            getMessages();          
        })
    };

    const isGroupChat = computed(() => {
      return route.path.indexOf("/group-chat") !== -1;
    });

    const getMessages = async () => {
      const mMessages = await getAxios("/getBordMessage/" + props.dis_type_entity + "/" + props.dis_knum_entity);

      let mUser = "";
      let mDate = "1970-01-01 00:00:00";
      mMessages.results.forEach((el) => {

        el.files = JSON.parse(el.files);
        if (el.files.length === 0) el.files = "";

        const duration = moment.duration(moment.default(el.time, "YYYY-MM-DD hh:mm:ss").diff(moment.default(mDate, "YYYY-MM-DD hh:mm:ss")))
        if ( (duration.asMinutes() < 1) && el.name == mUser ) {
            el.header = "";
        } 
        mUser = el.name;
        mDate = el.time;
        state.dernierID = el.dis_n_seq;
      })

      state.messages = mMessages.results;
      scrollBottom();
    }

    const scrollBottom = () => {
      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight-100;
        }
      }, 1);
    }
   
    const submitUpload = () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];

      if (file) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function(e) {
              if(e.target) state.files.push({ name: file.name, data: e.target.result });
          };
      }
    }

    const deleteFile = (file) => {
      for( var i = 0; i < state.files.length; i++){ 
        if ( state.files[i].name === file.name) { 
          state.files.splice(i, 1); 
        }
      }
      scrollBottom();
    }

    let refreshInterval = "" as any;

    onBeforeUnmount(() => {
      clearInterval(refreshInterval);
    });

    onMounted(async() => {
        if (!store.getters.isUserAuthenticated){
            router.push({ name: "sign-in" });
        }

        if (props.dis_type_entity == 'ticket') {
          state.title = "Discussion autour du ticket";
        }else {
          state.title = "T'Chat global Still ERP !";
        }
        

        await getMessages();
        refreshInterval = setInterval(async function(){
          const mData = await getAxios("/messageDernierID/" + props.dis_type_entity + "/" + props.dis_knum_entity);
          if (mData.results.dis_n_seq != state.dernierID) {
              await getMessages();
          }
        },9000);

      if (!messagesRef.value) {
        return;
      }
      ScrollComponent.bootstrap();
    });

    return {
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      contacts,
      isGroupChat,
      state,
      handleCommand,
      submitUpload,
      deleteFile
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

